<template>
  <nav>
    <div class="nav-left">
      <router-link to="/">
        <Icon id="icon" viewBox="0 0 131 131"/>
        <Logo id="logo" viewBox="0 0 413 73"/>
      </router-link>
      <a href="#about">About</a>
      <a href="#features">Features</a>
      <a href="#pricing">Pricing</a>
    </div>
    <div class="nav-right">
      <a class="login-button" href="/login" v-if="!isUserAuthenticated()">Login</a>
      <a class="login-button" href="/user" v-else>{{getUserEmail()}}</a>
      <div>
        <router-link to="/download">
          <Button class="pink-button">Download app</Button>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import Icon from "@timenaut/timenaut-vue-common/lib/assets/img/icon.svg";
import Logo from "@timenaut/timenaut-vue-common/lib/assets/img/timenaut-with-logo-light.svg";
import {Button} from "@timenaut/timenaut-vue-common";
import {isUserAuthenticated, getUserEmail} from "@/util/auth_util";

@Options({
  components: {
    Logo,
    Icon,
    Button
  }
})
export default class Navigation extends Vue {
  isUserAuthenticated = isUserAuthenticated;
  getUserEmail = getUserEmail;
}
</script>
<style scoped>
nav {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  margin: 35px 100px 50px;
}

.nav-left,
.nav-right {
  display: flex;
  align-items: center;
}

.nav-left > a,
.nav-right > a {
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
  color: var(--white);
  margin-right: 60px;
}

.button.pink-button:disabled {
  color: #a3a8cc;
  background-color: #eedff2;
}

.button.pink-button {
  min-width: 240px;
  background-color: #f4b4c7;
  color: var(--accent);
}

.button.pink-button:active,
.button.pink-button:hover {
  background-color: #eedff2;
}

.button.pink-button:active {
  border-color: #dba5f5;
}

#icon {
  width: 42px;
  height: 42px;
}

#logo {
  width: 233px;
  height: 42px;
  margin-right: 46px;
}

.login-button {
  margin-right: 76px;
}

@media (max-width: 1280px) {
  #logo {
    display: none;
  }
}

@media (min-width: 1281px) {
  #icon {
    display: none;
  }
}
</style>
